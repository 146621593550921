import React from 'react';
import s from './styles.module.css'

const InputSetting = ({
                          onChange, value, min = 0, step = 0.5, max = null, disabled = false, onBlur = () => {
    }, onFocus = () => {
    }
                      }: any) => {
    const handleWheel = (e: WheelEvent) => {
        if (document.activeElement === e.target) {
            e.preventDefault();
        }
    };
    return (
        <input
            onWheel={(e) => e.preventDefault()}
            onFocus={(e) => {
                onFocus();
                window.addEventListener("wheel", handleWheel, {passive: false});
            }}
            onBlur={(e) => {
                onBlur();
                window.removeEventListener("wheel", handleWheel);
            }}
            min={min}
            className={s.input}
            step={step}
            type="number" value={value}
            onKeyDown={(e: any) => {
                const invalidChars = min === 0 ? ['-', '+', 'e', 'E',] : ['+', 'e', 'E',];

                if (invalidChars.includes(e.key)) {
                    e.preventDefault();
                }
            }} onChange={(e: any) => {

            if (max !== null && +e.target?.value >= max) {
                onChange(max)
            } else if (+e.target?.value >= min && (max !== null ? +e.target?.value < max : true)) {
                onChange(e.target?.value)
            }
        }}/>
    );
};

export default InputSetting;